import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import cssCalcBetweenViewport from '../utils/cssCalcBetweenViewport';

export type ContentWrapperVariant = 'left' | 'checkout' | 'continuous' | 'searchList' | 'searchMap' | 'embed';

const searchListCss = css`
    max-width: none;
    width: auto;
    box-sizing: content-box;
    padding: 0 ${cssCalcBetweenViewport(24, 76)};
    position: relative;
`;
const getCss = (variant: ContentWrapperVariant) => {
    switch (variant) {
        case 'checkout':
            return css`
                --vertical-padding: 7rem;
                @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
                    padding-top: var(--vertical-padding);
                }
                padding-top: 10rem;
                padding-bottom: var(--vertical-padding);
                display: flex;
                width: 100%;
            `;
        case 'left':
            return css`
                padding-left: var(--wrapperPadding);
                @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
                    margin-left: calc((100vw - var(--max-viewport) * 1px) / 2 - var(--wrapperPadding));
                    margin-right: 0;
                }
            `;
        case 'continuous':
            return css`
                padding: 0;
            `;
        case 'embed':
            return css`
                padding: 0;
                max-width: 100%;
                margin: 0;
                position: relative;
            `;
        case 'searchList':
            return searchListCss;
        case 'searchMap':
            return css`
                ${searchListCss};
                height: 100vh;
                position: relative;
                @media (max-width: ${({ theme }) => theme.mediaQueriesValues.m - 1}px) {
                    padding: 0;
                }

                @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
                    height: auto;
                }
            `;
        default:
            throw new UnreachableCaseError(variant);
    }
};

/**
 * Should be used in combination with the Page component
 */
const ContentWrapper = styled.div<{ variant?: ContentWrapperVariant }>`
    max-width: calc(140.8rem + var(--wrapperPadding) * 2);
    width: 100%;
    margin: 0 auto ${({ theme }) => theme.spacing['60_Large']};
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-bottom: 0;
    }
    padding: 0 var(--wrapperPadding);
    box-sizing: border-box;
    ${({ variant }) => variant && getCss(variant)};
`;

export default ContentWrapper;
